import React from "react";
import { Layout } from "../components/common";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import ContactForm from "../components/ContactForm";
import extractCodeInjectionElements from "../utils/extractCodeInjectionElements";
import { MetaData } from "../components/common/meta";

const IndexPage = ({ data, location }) => {
    const page = data.ghostPage;

    const styleElements = extractCodeInjectionElements(
        `style`,
        ``.concat(page.codeinjection_head || ``, page.codeinjection_foot || ``)
    );

    return (
        <>
            <MetaData data={data} location={location} type="website" />
            <Helmet>
                <style type="text/css">
                    {`${styleElements.map((e) => e?.innerText).join(``)}`}
                </style>
            </Helmet>

            <Layout>
                <article className="content" style={{ textAlign: `center` }}>
                    <section
                        className="content-body"
                        dangerouslySetInnerHTML={{
                            __html: page.childHtmlRehype.html,
                        }}
                    ></section>
                    <ContactForm />
                </article>
            </Layout>
        </>
    );
};

IndexPage.propTypes = {
    data: PropTypes.shape({
        ghostPage: PropTypes.shape({
            html: PropTypes.string.isRequired,
            codeinjection_styles: PropTypes.string,
            codeinjection_head: PropTypes.string,
            codeinjection_foot: PropTypes.string,
            childHtmlRehype: PropTypes.object,
        }).isRequired,
    }).isRequired,
    location: PropTypes.object.isRequired,
};

export const query = graphql`
    query {
        ghostPage(slug: { eq: "home" }) {
            ...GhostPageFields
            childHtmlRehype {
                html
            }
        }
    }
`;

export default IndexPage;
