const extractCodeInjectionElements = (type, scriptString) => {
    let scriptElements = [];
    if (typeof document !== `undefined`) {
        // We create a script wrapper to render the scriptString to real HTML
        const scriptWrapper = document.createElement(`div`);
        scriptWrapper.innerHTML = scriptString;

        // Now we can use native HTML queries to get our script|style elements (in this case a
        // collection)
        const scriptsCollection = scriptWrapper.getElementsByTagName(type);

        // Lets map the collection to a "normal" array of script elements
        for (let i = 0; i < scriptsCollection.length; i++) {
            scriptElements.push(scriptsCollection.item(i));
        }
    }

    // And return them ...
    return scriptElements;
};

export default extractCodeInjectionElements;
